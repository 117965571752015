export const environment = {
  production: false,
  navType:'uat',
  BACKEND_API_BASE_URL: 'https://api.stoltuat.com/css-manager-apis/v1',
  
  AAD_CLIENT_ID: "a0f09368-570f-4ff2-b940-73d1b5438333",
  AAD_AUTHORITY: "https://login.microsoftonline.com/a86e0b89-48f7-4a70-8e01-1962b219c224",
  AAD_REDIRECT_URI: "https://cssoffice.stoltuat.com",
  AAD_GRAPH_ENDPOINT: 'https://graph.microsoft.com/v1.0/me',
  AAD_USER_API_SCOPE: 'api://b2de34a4-8900-4e01-bad2-3d65d25f0a11/.default'
};
