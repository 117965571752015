import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { environment } from 'src/environments/environment';
import { Observable } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class DocumentApiService {
  
  constructor(private readonly http: HttpClient) { }


  fetchDocumentDetail(id:string): Observable<any>{
    return this.http.get(`${environment.BACKEND_API_BASE_URL}/documents/${id}`);
  }

  fetchDocumentDetailByType(id: string, docType: string): Observable<any> {
    return this.http.get(`${environment.BACKEND_API_BASE_URL}/document-approval/${id}`, {
      params: { docType: docType }
    });
  }
  approveDocumentDetail(id: any,formData:any): Observable<any> {
    return this.http.put(`${environment.BACKEND_API_BASE_URL}/document-approval/${id}`, formData );
  }

  // addDocumentData(postData:any): Observable<any>{
  //   return this.http.post(`${environment.CREW_APP_BACKEND_API_BASE_URL}/documents`, postData);
  // }



  fetchCategoryType(endpoint:string): Observable<any>{
    return this.http.get(`${environment.BACKEND_API_BASE_URL}/master-data/${endpoint}`);
  }

  fetchFormElementsForType(endpoint:string): Observable<any>{
    return this.http.get(`${environment.BACKEND_API_BASE_URL}/master-data/${endpoint}`);
  }

  fetchDocumentList(query_string:string): Observable<any>{
    return this.http.get(`${environment.BACKEND_API_BASE_URL}/documents?${query_string}`);
  }

  addDocumentData(postData:any): Observable<any>{
    return this.http.post(`${environment.BACKEND_API_BASE_URL}/documents`, postData);
  }


  deleteDocument(id: string) {
    return this.http.delete(`${environment.BACKEND_API_BASE_URL}/documents/${id}`);
  }

  public getChangedFields(initialValue: any, currentValue: any): string[] {
    const changedFields: string[] = [];
    Object.keys(initialValue).forEach((key) => {
      const originalValue = this.normalizeFieldValue(initialValue[key]);
      const currentValueField = this.normalizeFieldValue(currentValue[key]);
      if (Array.isArray(originalValue) && Array.isArray(currentValueField)) {
        if (originalValue.length !== currentValueField.length ||
            !originalValue.every((value, index) => value === currentValueField[index])) {
          changedFields.push(key);
        }
      } else if (originalValue !== currentValueField) {
        changedFields.push(key);
      }
    });
    return changedFields;
  }

  public normalizeFieldValue(value: any): any {
    if (typeof value === 'string') {
      return value.toLowerCase().trim();
    }
    return value;
  }
  
}
