import { Injectable, signal } from '@angular/core';
import { SnlDropdowns } from '@interfaces';
import { BehaviorSubject } from 'rxjs';



@Injectable({
  providedIn: 'root'
})
export class CrewSelfService {
   snlDropdownData = signal<SnlDropdowns>({
    countriesData:[],
    relationshipsData: [],
    maritalStatusData:[],
    nationalityData: [],
   })
  private readonly role$: BehaviorSubject<any> = new BehaviorSubject(0);
  
  constructor() { 
    // This is intentional
  }

  setUserRole(role: any) {
    this.role$.next(role);
  }

  getUserRole() {
    return this.role$.asObservable();
  }

}