import { Injectable } from '@angular/core';
import { IToastConfig, ToastService } from 'snl-complib';


@Injectable({
  providedIn: 'root',
})
export class ToasterService {
  public postion: "bottom-right" | "top-center" = "bottom-right";
  constructor(
    private toastService: ToastService,

  ){
    this.postion = 'bottom-right';
  }
  openToast(type: any, message: any) {
    const toastConfig: IToastConfig = {
      type: type,
      message: message,
      addTitle: true,
      addProgressBar: true,
      timeout: 5000,
      position: this.postion,
      multiAlerts:true
    };
    this.toastService.open(toastConfig);
  }

}
