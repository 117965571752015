import { Injectable } from '@angular/core';
import { AuthService } from '../auth/auth.service';
 
import { Router } from '@angular/router';
import { APPROVALS_ROLES_MODULES } from '@constants';
@Injectable({
  providedIn: 'root',
})
export class RolePermissionService {
  
  constructor(
    private authService: AuthService,
    private router: Router
  ) {
  }


  checkUserPermission(role: number): boolean {
    const userRoleData = RolePermissionService.getUserAccessRoleData(role);
    if (this.authService.UserObj().modules) {
      if (userRoleData.length === 0) {
        return false;
      }
      const approvalModule = new Set(this.authService.UserObj().modules);

      return userRoleData.some(item => approvalModule.has(item));
    }
    return false;
  }

  static getUserAccessRoleData(currentUserRole:number)
  {
    type RoleKey = keyof typeof APPROVALS_ROLES_MODULES;
    const accessKey = `ROLE_${currentUserRole}` as RoleKey;
    const access_module = (accessKey in APPROVALS_ROLES_MODULES)? APPROVALS_ROLES_MODULES[accessKey]:[];
    return access_module;
  } 

 checkPermission(arrModules: string[]): boolean {
    if (this.authService.UserObj().modules) {
      if (arrModules.length === 0) {
        return false;
      }
      const approvalModule = new Set(this.authService.UserObj().modules);

      return arrModules.some(item => approvalModule.has(item));
    }
    return false;
  }
}
